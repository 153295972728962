.cmp-content-management-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto; /* Center the page container */
  max-width: 1200px; /* Adjust as needed for your layout */
}

.cmp-content-management-page h2 {
  color: #ffffff; /* Light text color */
  margin-bottom: 1rem; /* Adjust spacing below header */
}

.cmp-content-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem; /* Add spacing between content items */
}

.cmp-content-type-title {
  color: #ffffff; /* Light text color */
  text-align: center;
  margin-top: 1rem; /* Reduced margin-top */
  margin-bottom: 0.5rem; /* Reduced margin-bottom */
  font-size: 1.2rem;
}

.cmp-generate-new-quiz,
.cmp-see-content {
  background-color: #4169e1; /* Royal blue */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.cmp-generate-new-quiz:hover,
.cmp-see-content:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.cmp-loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fc8600; /* Royal blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cmp-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cmp-popup-content {
  background: #0d1b2a; /* Match the app's theme */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden; /* Prevent overflow */
  position: relative;
}

.cmp-popup-title {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 1.5rem; /* Increased font size for the title */
  text-align: center;
}

.cmp-popup-scroll {
  overflow-y: auto;
  max-height: calc(80vh - 100px); /* Adjust as needed */
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Break words when necessary */
  word-break: break-word; /* Break words at appropriate points */
  color: #ffffff; /* Ensure text color is white */
  background-color: #0d1b2a; /* Ensure background color is dark */
  padding: 1rem;
}

.cmp-popup-scroll pre {
  background-color: #0d1b2a; /* Dark background for text */
  color: #ffffff; /* White text color */
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Break words when necessary */
  word-break: break-word; /* Break words at appropriate points */
  font-size: 1.2rem; /* Increased font size for content text */
}

.cmp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff; /* Light text color */
  background: none; /* Remove button background */
  border: none; /* Remove button border */
}

.cmp-close-button:hover {
  color: #fc8600; /* Firebase orange on hover */
}

.cmp-content-item {
  background-color: #0d1b2a; /* Dark background color */
  color: #ffffff; /* Light text color */
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 5px;
  width: 100%;
  min-width: 450px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Changed from center to left */
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Break words when necessary */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  box-sizing: border-box; /* Ensure padding is included in width */
  position: relative; /* Added to enable absolute positioning of the delete button */
}

.cmp-delete-content {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem; /* Reduced font size */
  color: #ffffff; /* White text color */
  background: #fc8600; /* Firebase orange background */
  border: none; /* Remove button border */
  cursor: pointer;
  padding: 0.2rem 0.4rem; /* Add padding to make the button smaller */
  border-radius: 3px; /* Add border radius for rounded corners */
}

.cmp-delete-content:hover {
  background: #ffffff; /* Change background color on hover */
  color: #fc8600; /* Change text color on hover */
}

.cmp-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cmp-popup-content {
  background: #0d1b2a; /* Match the app's theme */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden; /* Prevent overflow */
  position: relative;
  text-align: center; /* Center text in the modal */
}

.confirm-button {
  background-color: #fc8600; /* Firebase orange */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.confirm-button:disabled {
  background-color: #e25700; /* Darker orange when disabled */
  cursor: not-allowed;
}

.confirm-button:hover:not(:disabled) {
  background-color: #e25700; /* Darker orange on hover */
}

.cancel-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #365ebd; /* Darker blue on hover */
}
