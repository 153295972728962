.performance-history {
  padding: 20px;
  color: #ffffff; /* Light text color */
  text-align: center;
}

.performance-history h2 {
  color: #ffffff; /* Light text color */
}

.performance-history .timeframe-select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.performance-history .timeframe-select label {
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.performance-history .timeframe-select input {
  display: none;
}

.performance-history .timeframe-select span {
  padding: 5px 10px;
  background-color: #0d1b2a; /* Dark background */
  border-radius: 5px;
  color: #ffffff; /* Light text */
}

.performance-history .timeframe-select input:checked + span {
  background-color: #4169e1; /* Accent color */
}

.performance-history .slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #4169e1; /* Accent color */
  transition: 0.3s;
  z-index: 0;
}

.performance-history .timeframe-select input:checked + span + .slider {
  width: calc(33.33% - 20px);
}

.performance-history .stats-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.performance-history .stats-card {
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  text-align: center; /* Center text */
}

.performance-history .stats-card .stat {
  flex: 1;
}

.performance-history .stats-card .stat h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.performance-history .stats-card .stat p {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.performance-history .charts-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.performance-history .chart-card {
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center text */
}

.performance-history .quizzes-list {
  margin-top: 20px;
}

.performance-history .quiz-card {
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left; /* Left-align text */
}

.performance-history .quiz-card h4 {
  color: #ffffff; /* Light text color */
}

.performance-history .attempt-item {
  background-color: #2e3b55; /* Slightly lighter background for attempts */
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.performance-history .attempt-info {
  flex-grow: 1;
}

.performance-history .view-details-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  background-color: #4169e1; /* Accent color */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.performance-history .view-details-button:hover {
  background-color: #fc8600; /* Darker accent color on hover */
}

.performance-history .back-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4169e1; /* Accent color */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.performance-history .back-button:hover {
  background-color: #fc8600; /* Darker accent color on hover */
}
