body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b263b; /* Dark dark blue background color */
  color: #ffffff; /* Light text color */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #1b263b; /* Lighter but still dark forefront */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 2rem auto;
  max-width: 1200px;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.header-top-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo-title {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.logo {
  max-height: 200px; /* Increased logo size */
  width: auto;
}

.app-title {
  margin: 0;
  font-size: 2.5rem; /* Increased font size */
  color: #ffffff; /* Accent color */
}

.tagline {
  margin: 0;
  font-size: 1.2rem;
  color: #bbbbbb; /* Light gray for tagline */
}

.page-content {
  width: 100%;
  max-width: 800px; /* Adjust this as needed */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.loading-spinner {
  border: 16px solid #1b263b; /* Dark border */
  border-top: 16px solid #fc8600; /* Royal blue accent color for spinner */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #444444;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #2e2e2e; /* Dark scrollbar track color */
}

.back-button {
  margin-right: 10px;
  margin-top: 10px;
  background-color: #4169e1; /* Royal blue */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.app-header .button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.active-persona-title {
  cursor: pointer; /* Pointer cursor on hover */
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.active-persona-title:hover {
  color: #fc8600; /* Highlight text in orange on hover */
  text-decoration: underline; /* Underline text on hover */
}

.active-persona-card {
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  border-radius: 10px;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 20px; /* Added padding below the card */
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the text */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}

.active-persona-details {
  margin-top: 10px;
  margin-bottom: 10px;
}

.active-persona-details p {
  margin: 5px 0;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center; /* Center the buttons */
}

.button-common {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-common:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.logo-title {
  cursor: pointer; /* Pointer cursor on hover */
}

.header-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  position: relative;
}

.header-buttons-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.header-button {
  background-color: #fc8600; /* Royal blue */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.header-button:hover {
  background-color: #4169e1; /* Firebase orange on hover */
}
.about-page,
.howto-page {
  padding: 20px;
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text color */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  text-align: left; /* Left align the text */
}

.about-page h1,
.howto-page h1 {
  font-size: 2.5rem; /* Larger font size for the main header */
  color: #fc8600; /* Accent color for headers */
  margin-bottom: 20px;
}

.about-page h2,
.howto-page h2 {
  font-size: 2rem; /* Font size for subheaders */
  color: #707070; /* Accent color for subheaders */
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-page h3,
.howto-page h3 {
  font-size: 1.5rem; /* Font size for smaller subheaders */
  color: #4169e1; /* Accent color for subheaders */
  margin-top: 15px;
  margin-bottom: 10px;
}

.about-page p,
.howto-page p {
  font-size: 1.2rem; /* Font size for paragraphs */
  line-height: 1.5; /* Line height for better readability */
  margin-bottom: 20px;
}

.about-page strong,
.howto-page strong {
  color: #4169e1; /* Different accent color for emphasis */
}

.delete-button {
  background-color: #fc8600; /* Firebase orange */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.delete-button:hover {
  background-color: #e25700; /* Darker orange on hover */
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-content {
  background: #0d1b2a; /* Match the app's theme */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.confirm-button {
  background-color: #fc8600; /* Firebase orange */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.confirm-button:disabled {
  background-color: #e25700; /* Darker orange when disabled */
  cursor: not-allowed;
}

.confirm-button:hover:not(:disabled) {
  background-color: #e25700; /* Darker orange on hover */
}

.cancel-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #365ebd; /* Darker blue on hover */
}
