.attempt-page {
  padding: 20px;
  max-width: 600px;
  background-color: #0d1b2a; /* Lighter but still dark forefront */
  color: #ffffff; /* Light text color */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative; /* Enable absolute positioning for delete button */
}

.back-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.back-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.delete-attempt-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #ffffff; /* White text color */
  background-color: #fc8600; /* Firebase orange */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
}

.delete-attempt-button:hover {
  background-color: #ffffff; /* Change background color on hover */
  color: #fc8600; /* Change text color on hover */
}

.attempt-page h2 {
  margin-top: 0;
  color: #ffffff; /* Light text color */
}

.attempt-page .score-text {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: #ffffff; /* Light text color for the "Score" text */
}

.attempt-page .score-value.red {
  color: #e14141; /* Royal blue color for low scores */
}

.attempt-page .score-value.green {
  color: #10c33d; /* Green color for high scores */
}

.attempt-page .score-value {
  font-weight: bold;
}

.attempt-page ul {
  list-style-type: none;
  padding: 0;
}

.attempt-page li {
  margin-bottom: 15px;
  color: #ffffff; /* Light text color */
}

.attempt-page .response-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.attempt-page .response-title {
  font-weight: bold;
  min-width: 150px; /* Adjust as necessary */
}

.attempt-page .status-value.red {
  color: #e14141; /* Red color for incorrect status */
}

.attempt-page .status-value.green {
  color: #10c33d; /* Green color for correct status */
}

.attempt-page hr {
  border: 0;
  border-top: 1px solid #444444; /* Dark line color */
  margin: 15px 0;
}

.attempt-page .attempt-id {
  font-size: 0.8rem;
  margin-top: 20px;
  color: #bbbbbb; /* Light gray color */
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirmation-content {
  background: #0d1b2a; /* Match the app's theme */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden; /* Prevent overflow */
  position: relative;
  text-align: center; /* Center text in the modal */
}

.confirm-button {
  background-color: #fc8600; /* Firebase orange */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.confirm-button:disabled {
  background-color: #e25700; /* Darker orange when disabled */
  cursor: not-allowed;
}

.confirm-button:hover:not(:disabled) {
  background-color: #e25700; /* Darker orange on hover */
}

.cancel-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #365ebd; /* Darker blue on hover */
}
