.sidebar {
  width: 400px; /* Increased width for better fit */
  height: 100%;
  background-color: #1b263b; /* Lighter but still dark foreground */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: -420px; /* Adjusted to match increased width */
  top: 0;
  bottom: 0;
  overflow-y: auto; /* Enable vertical scrollbar */
  z-index: 1000;
  transition: left 0.3s ease, visibility 0.3s ease;
  visibility: hidden; /* Hide the sidebar content */
}

.sidebar.open {
  left: 0; /* Slide the sidebar in when open */
  visibility: visible; /* Show the sidebar content */
}

.sidebar h2 {
  margin-top: 0;
  color: #ffffff; /* Light text color */
  text-align: center; /* Center align text */
}

.sidebar h3 {
  color: #ffffff; /* Light text color */
  text-align: center; /* Center align text */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 15px;
  cursor: pointer;
  color: #ffffff; /* Light text color */
}

.sidebar li .quiz-title {
  margin: 0 10px; /* Add margin to the sides */
  color: #ffffff; /* Light text color */
  cursor: pointer;
  background-color: #0d1b2a; /* Button shade */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between text and arrow */
  white-space: normal; /* Allow text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis to overflowing text */
  font-size: 0.9rem; /* Reduce font size for better fit */
  word-break: break-word; /* Break long words to fit */
  text-align: left; /* Left align text */
}

.sidebar li .quiz-title::after {
  content: "▼"; /* Dropdown arrow */
  font-size: 0.8em;
}

.sidebar li ul {
  margin-top: 10px;
  padding-left: 15px;
}

.sidebar li ul li {
  background-color: #2e2e2e; /* Slightly lighter shade */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px; /* Line break between attempts */
  text-align: center; /* Center align text */
}

.sidebar li ul li:hover {
  background-color: #444444; /* Hover effect */
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 10px; /* Adjust based on your needs */
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1001; /* Ensure the button is above the sidebar */
  transition: left 0.3s ease;
}

.sidebar-toggle.open {
  left: 410px; /* Adjusted to match increased width */
}

.overlay {
  display: none; /* Hide the overlay initially */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ensure the overlay is below the sidebar toggle button */
  transition: opacity 0.3s ease;
}

.overlay.open {
  display: block; /* Show the overlay when the sidebar is open */
  opacity: 1;
}

/* Custom scrollbar styles */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #444444; /* Dark scrollbar color */
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #2e2e2e; /* Dark scrollbar track color */
}
