.quiz-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 800px;
}

.quiz-form h2 {
  color: #ffffff; /* Light text color */
}

.back-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
}

.back-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

textarea {
  background-color: #0d1b2a; /* Dark input background */
  color: white;
  width: 100%;
  max-width: 600px;
  height: 200px;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #444444; /* Dark border color */
  font-size: 1rem;
  resize: vertical;
}

button[type="submit"] {
  background-color: #4169e1; /* Royal blue */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fc8600;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
