.audio-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.audio-form h2 {
  color: #ffffff; /* Light text color */
}

.audio-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.audio-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #444444; /* Dark border color */
  border-radius: 5px;
  margin-bottom: 1rem;
  background-color: #0d1b2a; /* Dark input background */
  color: #ffffff; /* Light text color */
}

.audio-form button {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.audio-form button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.example-audio {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.example-card {
  background-color: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  border-radius: 10px;
  padding: 12px;
  margin-top: 8px;
  min-width: 350px;
  max-width: 400px; /* Ensure the card has a maximum width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the text */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.example-audio h3 {
  color: #ffffff; /* Light text color */
}

.example-audio ul {
  list-style: none;
  padding: 0;
}

.example-audio li {
  margin-bottom: 10px;
}

.example-audio .example-link {
  color: #ffffff; /* Light text color */
  text-decoration: none;
}

.example-audio .example-link:hover {
  text-decoration: underline;
  color: #fc8600;
}

.example-audio .download-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none; /* Remove underline */
}

.example-audio .download-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.loading-spinner {
  margin-top: 10px;
  color: #fc8600;
}
