.quiz-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.quiz-page h2 {
  color: #ffffff; /* Light text color */
  margin-bottom: 10px; /* Add some spacing below the title */
}

.quiz-item-container {
  background-color: #0d1b2a; /* Dark background color */
  padding: 20px;
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box; /* Include padding in width */
}

.quiz-item {
  width: 100%;
}

.quiz-item input {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
  background-color: #1b263b; /* Slightly lighter dark input background */
  color: #ffffff; /* Light text color */
  border: 1px solid #444444; /* Dark border color */
}

.quiz-item button {
  background-color: #4169e1; /* Royal blue */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0.5rem;
}

.quiz-item button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.quiz-item .correct {
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.quiz-item .incorrect {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.response-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fc8600; /* Royal blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.explanation-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.explanation-button:hover {
  color: #fc8600; /* Firebase orange on hover */
}

.back-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
}

.back-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.retake-button {
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
}

.retake-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #0d1b2a; /* Dark background */
  color: #ffffff; /* Light text */
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff;
}

.close-button:hover {
  color: #4169e1; /* Royal blue on hover */
}

.correct::before {
  content: "✅";
  margin-right: 0.5rem;
}

.incorrect::before {
  content: "❌";
  margin-right: 0.5rem;
}
