.persona-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #0d1b2a; /* Lighter but still dark foreground */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.persona-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.persona-item {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  padding: 10px;
  border: 1px solid #444444; /* Dark border color */
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #2e2e2e; /* Dark input background */
  color: #ffffff; /* Light text color */
}

.persona-item.active {
  background-color: #4e4e4e; /* Slightly lighter background for active state */
}

.persona-actions {
  display: flex;
  gap: 10px;
  align-items: center; /* Ensure buttons are centered vertically */
}

.persona-button {
  width: 100px; /* Fixed width for consistency */
  height: 40px; /* Add height for consistency */
  padding: 0; /* Remove padding for consistent height */
  margin: 0; /* Remove margin for consistent height */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4169e1; /* Royal blue */
  color: #fff;
  text-align: center; /* Center align text */
  display: flex;
  align-items: center; /* Center align text vertically */
  justify-content: center; /* Center align text horizontally */
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

.persona-button.delete-button {
  background-color: #fc8600; /* Firebase orange */
}

.persona-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.persona-button.delete-button:hover {
  background-color: #4169e1; /* Royal blue on hover */
}
