.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align to the top initially */
  height: calc(100vh - 80px); /* Adjust based on header height */
  padding: 20px;
  padding-top: 50px; /* Add padding to the top to create space from the top */
  color: #ffffff; /* Light text color */
}

.login-button {
  margin-top: 20px; /* Add some space between the logo and the button */
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}
