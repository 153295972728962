.intro-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #1b263b; /* Dark background color */
  overflow: hidden;
  position: relative;
}

.intro-container {
  background-color: #0d1b2a; /* Dark blue background color */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.intro-container-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fc8600; /* Orange color */
  font-size: 24px;
  cursor: pointer;
}

.intro-logo {
  width: 200px;
  margin-top: 20px;
}

.slides-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  position: relative;
  padding: 0 40px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 300px; /* Ensures enough height for text */
}

.slide {
  width: 100%;
  max-width: 500px;
  text-align: center;
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s ease-in-out;
  position: absolute;
  top: 0;
  padding: 20px; /* Ensure there's padding for the text */
}

.slide.active {
  opacity: 1;
  transform: translateX(0);
}

.slide.left {
  opacity: 0;
  transform: translateX(-100%);
}

.slide h2 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 10px; /* Adds space between title and text */
}

.slide p {
  font-size: 1.2rem;
  color: #cccccc;
  margin-top: 0; /* Ensure no extra space at the top */
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #888;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #fc8600;
}
