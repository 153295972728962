.selection-page-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto; /* Adjust based on header height */
  padding: 20px;
  background-color: #0d1b2a; /* Lighter but still dark foreground */
  color: #ffffff; /* Light text color */
  margin-top: 5px;
  margin-bottom: 3rem; /* Add margin at the bottom */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for the container */
  border-radius: 10px; /* Optional: to match the overall design */
}

.selection-options-custom {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensure centering horizontally */
  justify-content: center; /* Ensure centering vertically */
  gap: 1rem;
  margin-top: 2rem; /* Increased margin at the top for better spacing */
  margin-bottom: 2rem; /* Added margin at the bottom for spacing */
  width: 100%;
  max-width: 500px;
}

.selection-options-custom h1 {
  font-size: 2rem; /* Increased font size */
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff; /* Light text color */
}

.selection-dropdown-custom {
  padding: 10px 15px; /* Decreased padding */
  font-size: 1rem; /* Decreased font size */
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 10px; /* More rounded corners */
  cursor: pointer;
  width: 100%;
  max-width: 200px; /* Decreased max-width */
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow for Safari */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpolygon fill='%23ffffff' points='0,0 10,0 5,7'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.selection-dropdown-custom option {
  background-color: #0d1b2a; /* Dark background color */
  color: #ffffff; /* Light text color */
  padding: 10px; /* Padding for options */
}

.selection-options-custom button {
  padding: 15px 20px;
  font-size: 1rem;
  background-color: #4169e1; /* Royal blue */
  color: #ffffff; /* Light text color */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 90%;
  max-width: 250px;
}

.selection-options-custom button:hover {
  background-color: #fc8600; /* Firebase orange on hover */
}

.selection-options-custom button:disabled {
  background-color: #444444; /* Disabled button color */
}
